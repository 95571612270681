<template>
  <ion-page>
    <cyan-page-wrapper
      :title="esAsistencia ? 'Asistencia del Cuerpo Electoral' : 'Directorio'"
      :nav1="esAsistencia ? 'ASISTENCIA' : 'DIRECTORIO'"
      :nav2="datosCentro.nombre"
      :ambito="ambito"
      backRoute="/4F"
      class="directorio"
    >
      <ion-spinner id="preloadSpinner" v-if="esAsistencia"></ion-spinner>
      <div class="mobileShow">
        <div class="banner nivel1">{{ datosCentro.nombre }}</div>
        <div class="banner nivel2">{{ ambito }}</div>
      </div>
      <div color="banner nodata" v-if="!datos">Cargando...</div>
      <div color="banner nodata" v-else-if="!datosWrapped.ok">
        {{ datosWrapped.reason || "Sin datos" }}
      </div>
      <div class="banner nodata" v-else-if="!conDatos">
        No hay datos para este centro.
      </div>

      <div v-else>
        <div>
          <ion-segment scrollable v-model="grupoSeleccionado">
            <ion-segment-button
              v-for="j in datos.groupsOrder"
              :key="j"
              :value="j"
            >
              {{ j }}
            </ion-segment-button>
          </ion-segment>

          <transition name="fade" mode="out-in">
            <div v-if="conGrupoSeleccionado" :key="grupoSeleccionado">
              <div v-for="o in datosGrupoSeleccionado.cargosOrder" :key="o">
                <div class="banner nivel1">{{ o.toUpperCase() }}</div>
                <div class="banner asistencia" v-if="esAsistencia">
                  Pulse <span class="sobreElCargo">sobre el cargo</span> para
                  marcar o desmarcar asistencia. Pulse en el nombre para ver la
                  ficha del contacto.
                </div>
                <ion-list lines="none">
                  <party-entry
                    v-for="v in datosGrupoSeleccionado.cargos[o]"
                    :key="v"
                    :element="datos.datosCargos[v]"
                    :altBaseRoute="$route.path"
                    :ignorarAsistencia="!esAsistencia"
                    :modoAsistencia="esAsistencia"
                  />
                </ion-list>
              </div>

              <div v-if="esAsistencia && false">
              <div class="banner nivel1">
                REGISTRAR NUEVO MIEMBRO
                {{
                  datosGrupoSeleccionado.esJunta
                    ? "(" + grupoSeleccionado + ")"
                    : "(CENTRO)"
                }}
              </div>

              <ion-grid>
                <ion-row class="ion-align-items-center">
                  <ion-col size="3"> Cargo </ion-col>
                  <ion-col size="9">
                    <ion-select
                      cancelText="Cancelar"
                      interface="action-sheet"
                      name="tipoCargo"
                      id="tipoCargo"
                      v-model="idCargo"
                      placeholder="Elija cargo..."
                    >
                      <ion-select-option
                        v-for="(label, id) in cargosGrupoSeleccionado"
                        :key="id"
                        :value="id"
                        >{{ label }}
                      </ion-select-option>
                    </ion-select>
                  </ion-col>
                </ion-row>
                <ion-row class="ion-align-items-center">
                  <ion-col size="3"> DUI </ion-col>
                  <ion-col size="9">
                    <ion-input
                      v-model="duiCargoRaw"
                      placeholder="DUI"
                      inputmode="numeric"
                      id="duiCargo"
                    >
                    </ion-input>
                  </ion-col>
                </ion-row>
                <ion-row class="ion-align-items-center">
                  <ion-col size="3"> Celular </ion-col>
                  <ion-col size="9">
                    <ion-input
                      v-model="telCargoRaw"
                      placeholder="6666-7777 (Opcional)"
                      inputmode="numeric"
                      id="telCargo"
                    >
                    </ion-input>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col>
                    <ion-button expand="block" @click="altaCargo">
                      Registrar
                    </ion-button>
                  </ion-col>
                </ion-row>
              </ion-grid>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </cyan-page-wrapper>
  </ion-page>
</template>

<script lang="ts">
import CyanPageWrapper, {
  store,
  defineComponent,
} from "@/components/CyanPageWrapper.vue";
import PartyEntry from "@/components/PartyEntry.vue";
import { territorios } from "@/modules/cyanRegions";
import ud from "@/modules/cyanUserData";

import {
  IonList,
  IonPage,
  IonSegment,
  IonSegmentButton,
  IonSpinner,
  IonGrid,
  IonRow,
  IonButton,
  IonCol,
  IonSelect,
  IonSelectOption,
  IonInput,
  alertController,
} from "@ionic/vue";
import { informationCircle } from "ionicons/icons";
import unreact from "@/modules/unreact";
import cyanRequest from "@/modules/cyanRequest";

export default defineComponent({
  name: "DirectorioStreamlined",
  components: {
    IonList,
    IonButton,
    IonPage,
    IonInput,
    IonSegment,
    IonSelect,
    IonSelectOption,
    IonSpinner,
    IonSegmentButton,
    IonGrid,
    IonRow,
    IonCol,
    CyanPageWrapper,
    PartyEntry,
  },
  setup() {
    return { informationCircle };
  },
  data() {
    return {
      grupoSeleccionado: "",
      duiCargoRaw: "",
      telCargoRaw: "",
      idCargo: 0,
    };
  },

  watch: {
    duiCargoRaw: function () {
      if (this.duiCargoRaw != this.duiCargo) {
        this.duiCargoRaw = this.duiCargo;
        const el = document.getElementById("duiCargo") as any;

        if (el) {
          el.value = this.duiCargo; // bug en ion-input
          if (
            el.children &&
            el.children[0] &&
            el.children[0].tagName == "INPUT"
          )
            el.children[0].value = this.duiCargo;
        }
      }
    },
    telCargoRaw: function () {
      if (this.telCargoRaw != this.telCargo) {
        this.telCargoRaw = this.telCargo;
        const el = document.getElementById("telCargo") as any;

        if (el) {
          el.value = this.telCargo; // bug en ion-input
          if (
            el.children &&
            el.children[0] &&
            el.children[0].tagName == "INPUT"
          )
            el.children[0].value = this.telCargo;
        }
      }
    },

    grupoSeleccionado() {
      store.commit("storeTabDirectorio", this.grupoSeleccionado);
    },
    datos() {
      const _t = this as any;
      if (!_t.datos) return;

      this.checkTabAlmacenada();
    },
  },
  computed: {
    duiCargo(): string {
      const f = this.duiCargoRaw.replace(/[^0-9]/g, "");
      if (f.length < 9) return f;
      return f.substr(0, 8) + "-" + f.substr(8, 1);
    },
    telCargo(): string {
      let f = this.telCargoRaw.replace(/[^0-9]/g, "");
      if (f.length > 0 && (f[0] != '6' && f[0] != '7')) f = '';
      if (f.length <= 4) return f;
      return f.substr(0, 4) + "-" + f.substr(4, 4);
    },
    esAsistencia() {
      return (this as any).$route.path.split("/")[1] === "jrslasist";
    },
    ambito() {
      const n = this.datosCentro.nivel as string;
      if (!(n in territorios)) return "";
      return territorios[n].nombre + ", " + territorios[n].nombrePadre;
    },
    datosCentro() {
      return ud("centro", { nivel: "SV", nombre: "Desconocido" });
    },
    conGrupoSeleccionado(): any {
      return !!(
        this.datos &&
        this.datos.groups &&
        this.datos.groups[this.grupoSeleccionado]
      );
    },
    datosWrapped(): any {
      return store.getters.directorioStreamlined;
    },
    datos(): any {
      const _t = this as any;

      if (!_t.datosWrapped || !_t.datosWrapped.ok || !_t.datosWrapped.value)
        return false;

      // Construir datos filtrados

      const dw = _t.datosWrapped.value;

      // Para el directorio general, eliminar asistenciaMarcable de datosCargos, el resto se queda igual

      if (!_t.esAsistencia) return dw;

      // Es asistencia. Aquí hay que trabajar de abajo arriba.

      const df = {
        groups: {},
        groupsOrder: [] as string[],
        datosCargos: {},
      };

      // Primero, trasladamos a datosCargos únicamente aquellos que tengan asistenciaMarcable.

      const dca = df.datosCargos as any;

      for (const e in dw.datosCargos) {
        if (dw.datosCargos[e].asistenciaMarcable) dca[e] = dw.datosCargos[e];
      }
      // Para cada grupo en los datos originales...

      for (const g of dw.groupsOrder) {
        const newCargos = {};
        const newCargosOrder = [];
        // Para cada sub-epígrafe...
        for (const c of dw.groups[g].cargosOrder) {
          const newList = [];
          for (const idCargo of dw.groups[g].cargos[c]) {
            if (typeof dca[idCargo] === "undefined") continue;
            newList.push(idCargo);
          }

          if (newList.length == 0) continue;

          newCargosOrder.push(c);
          (newCargos as any)[c] = newList;
        }

        if (newCargosOrder.length == 0) continue;

        (df as any).groups[g] = {
          cargos: newCargos,
          cargosOrder: newCargosOrder,
          tipo: dw.groups[g].tipo,
          esJunta: dw.groups[g].esJunta,
          junta: dw.groups[g].junta ?? null,
        };
        df.groupsOrder.push(g);
      }
      return df;
    },

    datosGrupoSeleccionado(): any {
      if (
        !this.conDatos ||
        !this.datos.groups ||
        !this.datos.groups[this.grupoSeleccionado]
      )
        return {};
      return this.datos.groups[this.grupoSeleccionado];
    },

    conDatos() {
      const d = this.datos as any;
      return d && d.groupsOrder && d.groupsOrder.length;
    },
    cargosGrupoSeleccionado() {
      if (!(this as any).conDatos) return {};
      return ud(
        (this as any).datosGrupoSeleccionado.esJunta
          ? "cargosJunta"
          : "cargosCentro",
        {}
      );
    },
  },
  mounted() {
    this.checkTabAlmacenada();
  },
  methods: {
    async altaCargo() {
      const ntc = this.cargosGrupoSeleccionado[this.idCargo];
      const problemas = [];

      if (!ntc) problemas.push("Debe especificar un cargo.");

      if (this.duiCargo.length != 10)
        problemas.push("Debe especificar un DUI válido.");

      if (this.telCargo.length != 0 && this.telCargo.length != 9)
        problemas.push('El teléfono no es válido.')

      if (problemas.length) {
        alertController
          .create({
            message: problemas.join(" "),
            buttons: ["OK"],
          })
          .then((alert) => alert.present());

        return;
      }

      const _t = this as any;

      if (
        !(await new Promise(function (resolve) {
          alertController
            .create({
              message:
                "¿Confirma que desea dar de alta el DUI " +
                _t.duiCargo +
                " como " +
                ntc +
                " para " +
                (_t.datosGrupoSeleccionado.esJunta
                  ? "la JRV " + _t.datosGrupoSeleccionado.junta
                  : "el centro") +
                "?",
              buttons: [
                {
                  text: "NO",
                  role: "cancel",
                  handler: function () {
                    resolve(false);
                  },
                },
                {
                  text: "SÍ",
                  handler: function () {
                    resolve(true);
                  },
                },
              ],
            })
            .then((alert) => alert.present());
        }))
      ) {
        return;
      }

      cyanRequest("jrsl/addCargo", {
        isPost: true,
        isModal: true,
        needsAuth: true,
        retries: 3,
        params: {
          tipo: _t.datosGrupoSeleccionado.esJunta ? "junta" : "centro",
          junta: _t.datosGrupoSeleccionado.junta ?? null,
          tipoCargo: _t.idCargo,
          dui: _t.duiCargo,
          telefono: _t.telCargo
        },
      }).then(function (d) {
        if (d.ok) {
          store.commit("storeDirectorioStreamlined", d);

          _t.duiCargoRaw = "";

          alertController
            .create({
              message: "Cargo creado con éxito.",
              buttons: ["OK"],
            })
            .then((alert) => alert.present());
        }
      });
    },

    checkTabAlmacenada() {
      const _t = this as any;
      const tabAlmacenada = unreact(store.state.currentTabDirectorio??'');

      if (!_t.datos || !_t.datos.groups || !_t.datos.groupsOrder) return;

      if (
        tabAlmacenada == _t.grupoSeleccionado &&
        _t.datos.groups[_t.grupoSeleccionado]
      )
        return;

      if (_t.datos.groups[tabAlmacenada]) {
        _t.grupoSeleccionado = tabAlmacenada;
      } else {
        _t.grupoSeleccionado = _t.datos.groupsOrder[0];
      }
    },
  },
  props: {},
});
</script>
<style scoped>
ion-list,
ion-item {
  background: transparent;
  --background: transparent;
}

.fade-enter {
  opacity: 0;
}
.fade-enter-to {
  opacity: 1;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease-out;
}

.fade-leave {
  opacity: 1;
}
.fade-leave-to {
  opacity: 0;
}

.acceso-vigilante ion-item {
  display: flex;
  flex-direction: column;
}

.acceso-vigilante ion-label {
  flex: 1 1 100px;
  text-align: right;
  color: var(--color-secondary);
  padding-right: 1em;
}
#app .acceso-vigilante ion-input {
  flex: 1 1 100px;
  width: 100px;
}
#preloadSpinner {
  display: none;
}
.infoIcon {
  color: var(--ion-color-primary);
  position: relative;
  top: 3px;
}
.asistencia {
  color: var(--ion-color-secondary);
  font-size: 95%;
  padding: 0 2vw;
}
.sobreElCargo {
  color: var(--ion-color-primary);
}
</style>